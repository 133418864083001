<template>
  <v-btn
    x-large
    color="error"
    elevation="0"
    block
    class="px-6 text-none text-body-1 font-weight-medium rounded-lg mt-4"
    @click="initSupportChat"
  >
    <v-icon v-text="'mdi-alert'" left />
    {{ $ml.get("reportError") }}
  </v-btn>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { initFreshChat, isNuvemClient } from "@/helpers/utils";
export default {
  name: "ErrorReportButton",
  computed: {
    ...mapGetters(["companyInSuite"]),
  },
  methods: {
    ...mapActions(["getHubspotToken"]),
    initFreshChat,
    isNuvemClient,
    async initSupportChat() {
      const { reseller_token } = this.companyInSuite;
      if (this.isNuvemClient(reseller_token)) {
        this.initFreshChat();
        return;
      }
      await this.getHubspotToken();
    },
  },
};
</script>
